.stepname{
    margin: 20px 10px;
    color: aliceblue;
    /* font-size: 1rem; */
}
.step{
    align-items: center;
    align-content: center;
}
.plans{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    gap: 30px;
}
.plan{
    display: flex;
    flex-direction: column;
    border-style: solid;    
    border-width:5px;
    gap: 10px;
    padding: 10px;
}
#bronze{
    background-color: #b08c5750;
    border-color: #B08D57;

}
#silver{
    background-color: #d8d8d853;
    border-color: #D8D8D8;
}
#gold{
    background-color: #e5b90b3c;
    border-color: #E5B80B;
}
.buynow{
    align-items: center;
    align-content: center;
    margin: 10px auto;
    color: white;
    /* padding: 1%; */
    border-radius: 10px;
    border-color: aliceblue;
    background-color: transparent;
    cursor: pointer;
    max-height: 30px;
    height: 30px;
    width: 100px;
}
.buynow:hover{
    font-size: 110%;
}
.payme{
    padding: 20px;
}
.paypal-button{
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    margin: 20px 0; /* Adds space above and below the button */
}