.home {
  z-index: 1;
    margin-top: 50px;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .power{
    position: absolute;
    top:0px !important;
    z-index: -1;
    justify-content: center;
  }
  .chathome {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* You can adjust the size and styling of .chathome as needed */
    /* width: 100%; */
    padding: 5px; /* Example padding */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
}
  .titlehome{
    /* font-size: 58px; */
    margin-top: 20px;
  }
  .news{
    background-color: #9F9F9F;
    width: 320px;
    height: 36px;
    margin: 10px auto;
    border-radius: 20px;
    background: #000;
    display: flex;
    flex-direction: row-reverse;
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.20);
  }
  .nn{
    margin: auto 19px ;
  }
  .n{
    display: flex;
    align-content: center;
    margin: auto auto auto 0px;
  }
  .arms {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }
  .chatbot{
    display: flex;
    justify-content: center;
    color: black;
  }
  .robothand {
    position: absolute;
    top: 0;
    left: 0;
  }

  .humanhand {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .robothand img, .humanhand img {
    width: 300px; /* Default size for larger screens */
    height: auto;
  }
  .powerr{
    height: 104px;
  }

  .aboutus{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    gap: 50px;
  }
  .aisolution{
    display: flex;
    flex-direction: row;
    gap: 80px;
    margin: 30px auto 0px auto ;
  }
  .aibuilder{
    display: flex;
    flex-direction: row-reverse;
    /* gap: 80px; */
    margin: 30px auto 0px auto ;
  }
  .crypto{
    display: flex;
    flex-direction: row;
    gap: 80px;
    margin: 30px auto 0px auto ;
  }
  .nft{
    display: flex;
    flex-direction: row-reverse;
    gap: 80px;
    margin: 30px auto 0px auto ;
  }
  .solution{
    max-width: 400px;
    display: flex;
    border: 2px #9F9F9F ;
    background-color: #ea0f0f;
    gap: 10px;
    padding: 30px;
    margin: 10px 0px;
    border-radius: 20px;
    background: #000;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.20);
  }
  .letsbut{
    width: 120px;
  }
  .aisol{
    /* width: 20%; */
    height: 250px;
    margin: auto;
    border: none;
    margin-bottom: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .robothand img, .humanhand img {
      width: 250px; /* Adjust the size for medium screens */
    }
    .powerr{
      height: 90px;
    }
    .chathome{
      padding: 20px;
    }
    .aisolution{
      display: flex;
      flex-direction: row;
      gap: 50px;
    }
  }
  
  @media (max-width: 768px) {
    .robothand img, .humanhand img {
      width: 150px; /* Adjust the size for small screens */
    }
    .powerr{
      height: 60px;
    }
    .aisolution{
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
    }
    .aibuilder{
      display: flex;
      flex-direction: column-reverse;
    }
    .crypto{
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
    }
    .nft{
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .robothand img, .humanhand img {
      width: 110px; /* Adjust the size for very small screens */
    }
    .powerr{
      height: 40px;
    }
  }
  /* Home.css */
.rtl {
  direction: rtl;
  text-align: right;
  .search-header__button {
    display: none;
}

}

.rtl .arms {
  flex-direction: row-reverse;
}

.rtl .aisolution, .rtl .aibuilder, .rtl .crypto, .rtl .nft {
  flex-direction: row-reverse;
}

/* Add any additional RTL-specific styles if necessary */
