.butt{
    position: relative;
    font-size: 1em;
    height: 100%;
    padding: 0.2em 0.8em;
    min-width: 113px;
    border-radius: 30px;
    border: 40px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    box-shadow: 2px 2px 3px #000000b4;
  }
  
  .cont {
    position: relative;
    padding: 3px;
    background: linear-gradient(60deg,rgba(99, 35, 98, 1), rgba(180, 163, 9, 1),rgba(0, 0, 128, 1));
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  
  
  .cont:hover {
    background: linear-gradient(190deg, #03a9f4, #f441a5);
    filter: blur(0.0em);
    transition: filter 0.4s ease;
  }

  @media screen and (max-width: 780px) {
    .butt{
      font-size:1em;
    }
    .cont {
      
      width: 90px;
    }
  }
  @media screen and (max-width: 580px) {
    .butt{
      font-size:15px;
      padding: 1.5px;
    }
    .cont {
      
      width: 90px;
    }
  }