.profile-section {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.profile-section img {
    border-radius: 50%;
    width: 40px;

    object-fit: cover;
}

.flech {
    display: flex;
    align-items: center;
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.profile-section:hover .flech {
    transform: scale(1.2); 
}

.menu {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 10px;
    z-index: 1000;
}

.menu-item {
    padding: 8px 10px;
    cursor: pointer;
    color: #333;
}
.menu-item-info {
    display: flex;
    flex-direction: row;
    padding: 8px 10px;
    cursor: pointer;
    color: #000000;
    p{
        color: #000000;
        text-align: left;
    }
    .infouser{
        margin-left: 5px;
    }
}
.menu-item:hover,.menu-item-info:hover {
    background-color: #4b4b4b31;
    border-radius: 30px;
}
