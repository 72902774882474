.form {
    --input-focus: #2d8cf0;
    --font-color: #323232;
    --font-color-sub: #666;
    --bg-color: beige;
    --main-color: black;
    padding: 20px;
    /* z-index: 2; */
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 35px;
    margin: 2% 15%;
    /* border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color); */
  }
  
  .title {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 25px;
  }
  
  .title span {
    color: var(--font-color-sub);
    font-weight: 600;
    font-size: 17px;
  }
  
  .input {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding: 5px 10px;
    outline: none;
  }
  
  .input::placeholder {
    color: var(--font-color-sub);
    opacity: 0.8;
  }
  
  .input:focus {
    border: 2px solid var(--input-focus);
  }
  
  .login-with {
    display: flex;
    gap: 20px;
  }
  
  .button-log {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    color: var(--font-color);
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    fill: var(--main-color);
  }
  
  .button-log:active, .button-confirm:active {
    box-shadow: 0px 0px var(--main-color);
    transform: translate(3px, 3px);
  }
  
  .button-confirm {
    margin: 50px auto 0 auto;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 17px;
    font-weight: 600;
    color: var(--font-color);
    cursor: pointer;
  }
  /*/*/
  body{
	overflow-x: hidden;
  }
  .log img{
	width: 200px;
	cursor: pointer;
  }


#layer-0 {
	background: rgba(92,71,255,0);
	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,1)) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%;
	background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1)) 25%, rgba(255,71,255,1) 100%;
	background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(255,71,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#ff47ff', GradientType=0 );
	height:400px;
	width:200vw;
	opacity:1;
	position:absolute;
	bottom:0;
	left:0;
	right: 0;
	margin: 0 -50%;
	overflow: hidden;
	transform: perspective(200px) rotateX(60deg);
	z-index: -5;
}

#layer-1 {
	background: rgba(92,71,255,1);
	background: -moz-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
	background: -webkit-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
	background: -o-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
	background: -ms-linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
	background: linear-gradient(45deg, rgba(92,71,255,1) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#5c47ff', GradientType=1 );
	height: inherit;
	width: inherit;
	opacity:1;
	position:absolute;
	top:0;
	left:0;
	right: 0;
	margin: auto;
	z-index: -5;
}

#layer-2 {
	background: rgba(92,71,255,0);
	background: -moz-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(92,71,255,0)), color-stop(50%, rgba(92,71,255,0)), color-stop(100%, rgba(92,71,255,1)));
	background: -webkit-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
	background: linear-gradient(135deg, rgba(92,71,255,0) 0%, rgba(92,71,255,0) 50%, rgba(92,71,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5c47ff', endColorstr='#5c47ff', GradientType=1 );
	height: inherit;
	width: inherit;
	opacity:1;
	position:absolute;
	top:0;
	left:0;
	right: 0;
	margin: auto;
	z-index: -5;
}

#layer-corner {
	background: rgba(71,255,203,1);
	background: -moz-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(71,255,203,1)), color-stop(54%, rgba(96,130,223,0)), color-stop(100%, rgba(117,24,240,0)));
	background: -webkit-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
	background: -o-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
	background: -ms-linear-gradient(top, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
	background: linear-gradient(to bottom, rgba(71,255,203,1) 0%, rgba(96,130,223,0) 54%, rgba(117,24,240,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffcb', endColorstr='#7518f0', GradientType=0 );
	height: inherit;
	width: inherit;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: -5;
	animation: layercorneranim 2.5s ease-in-out infinite alternate;	
}

#hey {
	width: 100%;
	background-color: #011;
	position: absolute;
	bottom: 253px; right: 0; left: 0;
	margin: auto;
	z-index: -10;
	transform: perspective(200px);
}

#layer-up {
	background: rgba(71,255,203,0);
	background: -moz-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(71,255,203,0)), color-stop(50%, rgba(71,255,203,0)), color-stop(100%, rgba(71,255,203,1)));
	background: -webkit-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 54%, rgba(71,255,203,1) 100%);
	background: -o-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
	background: -ms-linear-gradient(top, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
	background: linear-gradient(to bottom, rgba(71,255,203,0) 0%, rgba(71,255,203,0) 50%, rgba(71,255,203,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffcb', endColorstr='#47ffcb', GradientType=0 );
	height:300px;
	width: inherit;
	opacity: 1;
	position: absolute;
	bottom:0; left:0; right: 0; margin: auto;
	overflow: hidden;
	z-index: -5;
	animation: layerupanim 2.5s ease-in-out infinite alternate;	
}

#lines {
    background-size: 40px 40px;    
    background-image: repeating-linear-gradient(0deg, #60DCD3, #60DCD3 2px, transparent 1px, transparent 40px),repeating-linear-gradient(-90deg, #60DCD3, #60DCD3 2px, transparent 2px, transparent 40px);
    height:400px;
    width:100%;
    opacity:1;
    position:absolute;
    top:0;
    left:0;
    z-index:-4;
}

#mtn {
	background-color: purple;
	height:300px;
	width:100px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: -8;
	transform: perspective(600px);
}

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fd5353, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1111, 0 0 70px #ff1111, 0 0 80px #ff1111, 0 0 100px #ff1111, 0 0 150px #ff0000;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1111, 0 0 35px #ff1111, 0 0 40px #ff1111, 0 0 50px #ff1111, 0 0 75px #ff1111;
  }
}

@keyframes layerupanim
{
	from {
		height: 140px;
	}
	to {
		height: 155px;
	}
}

@keyframes layercorneranim
{
	from {
		height: 400px;
	}
	to {
		height: 540px;
	}
}
.hero-heading {
	color: #fff;
	font-family: 'Orbitron', sans-serif;
	font-size: 52px;
	text-transform: uppercase;
  }
  
  .lights {
	margin-top: -96px;
	width: 100%;
  }
  
  .light-box {
	height: 3px;
	margin-bottom: 20px;
	position: relative;
  }
  

  .light {
	background-color: #f9c3ff;
	border-radius: 5px;
	box-shadow: 0 0 30px 3px rgba(253, 93, 255);
  }
  
  .light-sides::before,
  .light-sides::after {
	content: "";
	background-color: #f9c3ff;
	border-radius: 5px;
	box-shadow: 0 0 30px 3px rgba(253, 93, 255);
	height: 3px;
	position: absolute;
	top: 0;
	width: 250px;
  }
  
  .light-sides::after {
	right:0;
  }
  
  .light-sides.small::before,
  .light-sides.small::after {
	width: 100px;
  }
  
  
  #overlay {
    animation: neon1 1.5s ease-in-out infinite alternate;
	/* position: absolute; */
	right: 0;
	left: 0;
	width: 100%;
	text-align: center;
	cursor: crosshair;
}
  
  .glitch-wrapper {
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
			justify-content: center;
	-webkit-box-align: center;
			align-items: center;
  }
  .glitch {
	color: white;
	font-size: 2rem;
	text-transform: upercase;
	position: relative;
	display: inline-block;
  }
  .glitch::before,
  .glitch::after {
	animation-delay: 5s;
	content: attr(data-text);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
  }
  .glitch::before {
	left: 2px;
	text-shadow: -2px 0 #49FC00;
	clip: rect(24px, 550px, 90px, 0);
	animation: glitch-anim-2 4s infinite linear alternate-reverse;
  }
  .glitch::after {
	left: -2px;
	text-shadow: -2px 0 #b300fc;
	clip: rect(85px, 550px, 140px, 0);
	animation: glitch-anim 3.5s infinite linear alternate-reverse;
  }
  @-webkit-keyframes glitch-anim {
	0% {
	  clip: rect(130px, 9999px, 15px, 0);
	}
	4.166666666666666% {
	  clip: rect(10px, 9999px, 39px, 0);
	}
	8.333333333333332% {
	  clip: rect(10px, 9999px, 51px, 0);
	}
	12.5% {
	  clip: rect(5px, 9999px, 90px, 0);
	}
	16.666666666666664% {
	  clip: rect(78px, 9999px, 54px, 0);
	}
	20.833333333333336% {
	  clip: rect(101px, 9999px, 134px, 0);
	}
	25% {
	  clip: rect(17px, 9999px, 102px, 0);
	}
	29.166666666666668% {
	  clip: rect(103px, 9999px, 102px, 0);
	}
	33.33333333333333% {
	  clip: rect(108px, 9999px, 61px, 0);
	}
	37.5% {
	  clip: rect(37px, 9999px, 7px, 0);
	}
	41.66666666666667% {
	  clip: rect(74px, 9999px, 17px, 0);
	}
	45.83333333333333% {
	  clip: rect(24px, 9999px, 23px, 0);
	}
	50% {
	  clip: rect(1px, 9999px, 112px, 0);
	}
	54.166666666666664% {
	  clip: rect(148px, 9999px, 147px, 0);
	}
	58.333333333333336% {
	  clip: rect(8px, 9999px, 19px, 0);
	}
	62.5% {
	  clip: rect(36px, 9999px, 44px, 0);
	}
	66.66666666666666% {
	  clip: rect(148px, 9999px, 120px, 0);
	}
	70.83333333333334% {
	  clip: rect(128px, 9999px, 45px, 0);
	}
	75% {
	  clip: rect(18px, 9999px, 18px, 0);
	}
	79.16666666666666% {
	  clip: rect(60px, 9999px, 123px, 0);
	}
	83.33333333333334% {
	  clip: rect(143px, 9999px, 131px, 0);
	}
	87.5% {
	  clip: rect(145px, 9999px, 7px, 0);
	}
	91.66666666666666% {
	  clip: rect(76px, 9999px, 96px, 0);
	}
	95.83333333333334% {
	  clip: rect(72px, 9999px, 45px, 0);
	}
	100% {
	  clip: rect(35px, 9999px, 94px, 0);
	}
  }
  @keyframes glitch-anim {
	0% {
	  clip: rect(130px, 9999px, 15px, 0);
	}
	4.166666666666666% {
	  clip: rect(10px, 9999px, 39px, 0);
	}
	8.333333333333332% {
	  clip: rect(10px, 9999px, 51px, 0);
	}
	12.5% {
	  clip: rect(5px, 9999px, 90px, 0);
	}
	16.666666666666664% {
	  clip: rect(78px, 9999px, 54px, 0);
	}
	20.833333333333336% {
	  clip: rect(101px, 9999px, 134px, 0);
	}
	25% {
	  clip: rect(17px, 9999px, 102px, 0);
	}
	29.166666666666668% {
	  clip: rect(103px, 9999px, 102px, 0);
	}
	33.33333333333333% {
	  clip: rect(108px, 9999px, 61px, 0);
	}
	37.5% {
	  clip: rect(37px, 9999px, 7px, 0);
	}
	41.66666666666667% {
	  clip: rect(74px, 9999px, 17px, 0);
	}
	45.83333333333333% {
	  clip: rect(24px, 9999px, 23px, 0);
	}
	50% {
	  clip: rect(1px, 9999px, 112px, 0);
	}
	54.166666666666664% {
	  clip: rect(148px, 9999px, 147px, 0);
	}
	58.333333333333336% {
	  clip: rect(8px, 9999px, 19px, 0);
	}
	62.5% {
	  clip: rect(36px, 9999px, 44px, 0);
	}
	66.66666666666666% {
	  clip: rect(148px, 9999px, 120px, 0);
	}
	70.83333333333334% {
	  clip: rect(128px, 9999px, 45px, 0);
	}
	75% {
	  clip: rect(18px, 9999px, 18px, 0);
	}
	79.16666666666666% {
	  clip: rect(60px, 9999px, 123px, 0);
	}
	83.33333333333334% {
	  clip: rect(143px, 9999px, 131px, 0);
	}
	87.5% {
	  clip: rect(145px, 9999px, 7px, 0);
	}
	91.66666666666666% {
	  clip: rect(76px, 9999px, 96px, 0);
	}
	95.83333333333334% {
	  clip: rect(72px, 9999px, 45px, 0);
	}
	100% {
	  clip: rect(35px, 9999px, 94px, 0);
	}
  }
  @-webkit-keyframes glitch-anim-2 {
	6.666666666666667% {
	  clip: rect(139px, 9999px, 35px, 0);
	}
	10% {
	  clip: rect(94px, 9999px, 53px, 0);
	}
	13.333333333333334% {
	  clip: rect(7px, 9999px, 89px, 0);
	}
	16.666666666666664% {
	  clip: rect(86px, 9999px, 78px, 0);
	}
	20% {
	  clip: rect(119px, 9999px, 58px, 0);
	}
	23.333333333333332% {
	  clip: rect(141px, 9999px, 101px, 0);
	}
	26.666666666666668% {
	  clip: rect(87px, 9999px, 70px, 0);
	}
	30% {
	  clip: rect(16px, 9999px, 9px, 0);
	}
	33.33333333333333% {
	  clip: rect(38px, 9999px, 73px, 0);
	}
	36.666666666666664% {
	  clip: rect(135px, 9999px, 26px, 0);
	}
	40% {
	  clip: rect(62px, 9999px, 0px, 0);
	}
	43.333333333333336% {
	  clip: rect(147px, 9999px, 134px, 0);
	}
	46.666666666666664% {
	  clip: rect(127px, 9999px, 52px, 0);
	}
	50% {
	  clip: rect(111px, 9999px, 54px, 0);
	}
	53.333333333333336% {
	  clip: rect(25px, 9999px, 12px, 0);
	}
	56.666666666666664% {
	  clip: rect(18px, 9999px, 35px, 0);
	}
	60% {
	  clip: rect(105px, 9999px, 13px, 0);
	}
	63.33333333333333% {
	  clip: rect(35px, 9999px, 3px, 0);
	}
	66.66666666666666% {
	  clip: rect(60px, 9999px, 137px, 0);
	}
	70% {
	  clip: rect(14px, 9999px, 65px, 0);
	}
	73.33333333333333% {
	  clip: rect(104px, 9999px, 141px, 0);
	}
	76.66666666666667% {
	  clip: rect(54px, 9999px, 15px, 0);
	}
	80% {
	  clip: rect(15px, 9999px, 62px, 0);
	}
	83.33333333333334% {
	  clip: rect(30px, 9999px, 88px, 0);
	}
	86.66666666666667% {
	  clip: rect(10px, 9999px, 131px, 0);
	}
	90% {
	  clip: rect(148px, 9999px, 78px, 0);
	}
	93.33333333333333% {
	  clip: rect(67px, 9999px, 18px, 0);
	}
	96.66666666666667% {
	  clip: rect(116px, 9999px, 115px, 0);
	}
	100% {
	  clip: rect(114px, 9999px, 104px, 0);
	}
  }
  @keyframes glitch-anim-2 {
	6.666666666666667% {
	  clip: rect(139px, 9999px, 35px, 0);
	}
	10% {
	  clip: rect(94px, 9999px, 53px, 0);
	}
	13.333333333333334% {
	  clip: rect(7px, 9999px, 89px, 0);
	}
	16.666666666666664% {
	  clip: rect(86px, 9999px, 78px, 0);
	}
	20% {
	  clip: rect(119px, 9999px, 58px, 0);
	}
	23.333333333333332% {
	  clip: rect(141px, 9999px, 101px, 0);
	}
	26.666666666666668% {
	  clip: rect(87px, 9999px, 70px, 0);
	}
	30% {
	  clip: rect(16px, 9999px, 9px, 0);
	}
	33.33333333333333% {
	  clip: rect(38px, 9999px, 73px, 0);
	}
	36.666666666666664% {
	  clip: rect(135px, 9999px, 26px, 0);
	}
	40% {
	  clip: rect(62px, 9999px, 0px, 0);
	}
	43.333333333333336% {
	  clip: rect(147px, 9999px, 134px, 0);
	}
	46.666666666666664% {
	  clip: rect(127px, 9999px, 52px, 0);
	}
	50% {
	  clip: rect(111px, 9999px, 54px, 0);
	}
	53.333333333333336% {
	  clip: rect(25px, 9999px, 12px, 0);
	}
	56.666666666666664% {
	  clip: rect(18px, 9999px, 35px, 0);
	}
	60% {
	  clip: rect(105px, 9999px, 13px, 0);
	}
	63.33333333333333% {
	  clip: rect(35px, 9999px, 3px, 0);
	}
	66.66666666666666% {
	  clip: rect(60px, 9999px, 137px, 0);
	}
	70% {
	  clip: rect(14px, 9999px, 65px, 0);
	}
	73.33333333333333% {
	  clip: rect(104px, 9999px, 141px, 0);
	}
	76.66666666666667% {
	  clip: rect(54px, 9999px, 15px, 0);
	}
	80% {
	  clip: rect(15px, 9999px, 62px, 0);
	}
	83.33333333333334% {
	  clip: rect(30px, 9999px, 88px, 0);
	}
	86.66666666666667% {
	  clip: rect(10px, 9999px, 131px, 0);
	}
	90% {
	  clip: rect(148px, 9999px, 78px, 0);
	}
	93.33333333333333% {
	  clip: rect(67px, 9999px, 18px, 0);
	}
	96.66666666666667% {
	  clip: rect(116px, 9999px, 115px, 0);
	}
	100% {
	  clip: rect(114px, 9999px, 104px, 0);
	}
  }