@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #f5f5f5;
}

html {
  background-color: #000000;
}

/* Heading styles */
h1 {
  font-size: 58px;
  font-weight: 600; /* Semi-bold */
}

h2 {
  font-size: 48px;
  font-weight: 500; /* Medium */
}

h3 {
  font-size: 30px;
  font-weight: 500; /* Medium */
}

h4 {
  font-size: 20px;
  font-weight: 300; /* Semi-bold */
}

h5 {
  font-size: 18px;
  font-weight: 600; 
}

h6 {
  font-size: 18px;
  font-weight: 400; /* Semi-bold */
}

/* Responsive styles */
@media (max-width: 1200px) {
 
}

@media (max-width: 992px) {
 
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
  h1 {
    font-size: 30px;
  }


}
