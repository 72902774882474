/* LanguageSwitcher.css */
#wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .ln {
    position: relative;
  }
  
  .dropdown-button {
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    align-items: center;
  }
  
  .dropdown-button img {
    width: 24px; /* Set the desired width */
    height: 16px; 
    margin-right: 8px; /* Space between the flag and text */
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    /* background: white; */
    /* border: 1px solid #ddd; */
    z-index: 1000;
  }
  
  .dropdown-menu button {
    margin-top: 8px;
    display: flex;
    align-items: center;
    /* padding: 8px; */
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
  }
  
  .dropdown-menu img {
    width: 24px; /* Set the desired width */
    height: 16px; /* Set the desired height */
    margin-right: 8px; /* Space between the flag and text */
  }
  
  .language-code {
    font-weight: bold;
    margin-right: 4px;
  }
  
  .language-name {
    font-size: 14px;
  }
  