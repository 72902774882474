.buttup{
    position: relative;
    font-size: 1em;
    height: 100%;
    padding: 0.2em 1.5em;
    border-radius: 30px;
    border: none;
    background-color: rgba(99, 35, 98, 1);
    color: #fff;
    cursor: pointer;
  }
  
  .contt {
    position: relative;
    padding: 3px;
    background: rgba(99, 35, 98, 1);
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  
  
  .cont:hover {
    background: rgba(99, 35, 98, 1);
    /* filter: blur(0.0em); */
    transition: filter 0.4s ease;
  }

  @media screen and (max-width: 780px) {
    .buttup{
      font-size:1em;
    }
    .cont {
      
      width: 90px;
    }
  }
  @media screen and (max-width: 580px) {
    .buttup{
      font-size:15px;
      padding: 1.5px;
    }
    .cont {
      
      width: 90px;
    }
  }