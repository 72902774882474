.wrapper {
  margin-top: 220px;
    --input-focus: #2d8cf0;
    --font-color: #323232;
    --font-color-sub: #666;
    --bg-color: beige;
    --bg-color-alt: transparent;
    --main-color: #323232;
      display: flex; 
      flex-direction: column;
      align-items: center;
  }
  .log img{
    width: 200px;
    cursor: pointer;
    }
  /* switch card */
  .switch {
    transform: translateY(-200px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 50px;
    height: 20px;
  }
  
  .card-side::before {
    position: absolute;
    content: 'Log in';
    left: -90px;
    top: 0;
    width: 100px;
    text-decoration: underline;
    font-weight: 600;
  }
  
  .card-side::after {
    position: absolute;
    content: 'Sign up';
    left: 50px;
    top: 0;
    width: 100px;
    text-decoration: none;
    font-weight: 600;
  }
  
  .toggle {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-colorcolor);
    transition: 0.3s;
  }
  
  .slider:before {
    box-sizing: border-box;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    border: 2px solid var(--main-color);
    border-radius: 5px;
    left: -2px;
    bottom: 2px;
    background-color: var(--bg-color);
    box-shadow: 0 3px 0 var(--main-color);
    transition: 0.3s;
  }
  
  .toggle:checked + .slider {
    background-color: var(--input-focus);
  }
  
  .toggle:checked + .slider:before {
    transform: translateX(30px);
  }
  
  .toggle:checked ~ .card-side:before {
    text-decoration: none;
  }
  
  .toggle:checked ~ .card-side:after {
    text-decoration: underline;
  }
  
  /* card */ 
  
  .flip-card__inner {
    width: 300px;
    height: 350px;
    position: relative;
    background-color: transparent;
    perspective: 1000px;
      /* width: 100%;
      height: 100%; */
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .toggle:checked ~ .flip-card__inner {
    transform: rotateY(180deg);
    
  }
  
  .flip-card__front {
    padding: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: transparent;
    gap: 20px;
  }
  .flip-card__back {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: transparent;
    gap: 20px;
  }
  .flip-card__back {
    width: 110%;
    transform: rotateY(180deg);
  }
  .LoginAnim{
    margin-top: 20px;
  }
  .flip-card__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .title {
    margin: 10px 0 ;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
  
  .flip-card__input {
    width: 250px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding: 5px 10px;
    outline: none;
  }
  
  /* .flip-card__input::placeholder {
    color: var(--font-color-sub);
    opacity: 0.8;
  }
   */
  .flip-card__input:focus {
    border: 2px solid var(--input-focus);
  }
  
  .flip-card__btn:active, .button-confirm:active {
    box-shadow: 0px 0px var(--main-color);
  }
  .error{
    font-size: 0.75rem;
  }
  .flip-card__btn {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 17px;
    font-weight: 600;
    color: var(--font-color);
    cursor: pointer;
  } 

  @media (max-width: 480px) {
    .flip-card__back {
      width: 100%;
      transform: rotateY(180deg);
    }
  }