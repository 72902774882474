.updt{
    /* margin:0 30px; */
    position: relative;
    padding: 2px;
    width: 300px;
    background: linear-gradient(60deg,rgba(99, 35, 98, 1), rgba(180, 163, 9, 1),rgba(0, 0, 128, 1));
    border-radius: 20px;
    transition: all 0.4s ease;
}
.prof-img{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prof-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.circle {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid purple;
  }
  
  label.upload-label {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* Hide by default */
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    transition: opacity 0.3s;
  }
  
  .circle:hover .upload-label {
    opacity: 1; /* Show label on hover */
  }
  
  .upload-icon {
    width: 50px;
    height: 50px;
    background-image: url(../../../assets/images/4889066.png); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  #file-upload {
    display: none;
  }
  
  #preview-image {
    display: flex; /* Show uploaded image */
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }
  /* From Uiverse.io by mrhyddenn */ 
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  max-width: 180px;
  margin-top: 20px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid purple;
  outline: 0;
  font-size: 17px;
  color: #fff;
  padding: 1px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #116399, #38caef);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #38caef;
  font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}