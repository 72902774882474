/* UserDashboard.css */
.container {
  display: grid;
  grid-template-columns: 0.25fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
}

.container.ltr {
  grid-template-areas: "menu content"; /* Menu on the left */
}
h3.rtl {
  font-size: 28px;
  text-align: right;
}
.container.rtl {
  grid-template-areas: "content menu"; /* Menu on the right */
    grid-template-columns: 0.25fr 1.25fr;
    margin-right: 20px;

}
