.men{
    display: flex;
    flex-direction: column;
    margin: 5rem .5em;
    gap: 16px;
}
.menu-but{
    display: flex;
    flex-direction: row;
    /* align-content: center; */
    position: relative;
    gap: 5px;    
    font-size: 1em;
    height: 100%;
    padding: 0.5em 1em;
    border-radius: 30px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    box-shadow: 2px 2px 3px #000000b4;
  }
  .cont-menu {
    position: relative;
    padding: 3px;
    background: linear-gradient(60deg, rgba(99, 35, 98, 1), rgba(180, 163, 9, 1), rgba(0, 0, 128, 1));
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  .cont-menu-off {
    position: relative;
    padding: 3px;
    border-radius: 30px;
    transition: all 0.4s ease;
  }
  .cont-menu-off:hover {
    background: rgba(99, 35, 98, 1);
    /* filter: blur(0.0em); */
    transition: filter 0.4s ease;
  }
  @media screen and (max-width: 580px) {
    .m1{
        display: none;
    }
    
  }