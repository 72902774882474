
.absolute {
  position: absolute;
  top: 100px !important;
  inset: 0;
  z-index: -1;
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
  gap: 10%;
}
.absolutee {
  position: absolute;
  top: 800px !important;
  inset: 0;
  z-index: -1;
  justify-content: center;
  display: inline-flex;
  flex-direction: row-reverse;
  gap: 10%;
}
.justify-center {
  justify-content: center;
}


.bg-shape1 {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  position: relative;
  animation: one 5s infinite;
}

.bg-shape2 {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  position: relative;
  animation: two 10s infinite;
}
.bg-shape3 {
  margin: 10%;
  width: 150px;
  height: 150px;
  border-radius: 9999px;
  position: relative;
  animation: two 8s infinite;
}

.bg-shape4 {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  position: relative;
  animation: one 5s infinite;
}

.bg-shape5 {
  width: 200px;
  height: 200px;
  border-radius: 9999px;
  position: relative;
  animation: two 10s infinite;
}
.bg-shape6 {
  margin: 10%;
  width: 150px;
  height: 150px;
  border-radius: 9999px;
  position: relative;
  animation: two 8s infinite;
}


@keyframes one {

  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: -100px;
    top: 70px;
  }


  50% {
    left: 20px;
    top: 150px;
  }

  75% {
    left: 50px;
    top: 100px;
  }


  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes two {

  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: -00px;
    top: 70px;
  }

  50% {
    left: 100px;
    top: 50px;
  }

  75% {
    left: 50px;
    top: 100px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}


.opacity-50 {
  opacity: .5;
}

.bg-blur {
  filter: blur(90px);
}

.bg-primary {
  background-color: #000080;
}

.bg-teal {
  background-color: #632362;
}

.bg-purple {
  background-color: #B4A309
}

@media (max-width: 576px) {
  .absolute {
    top: 1px;
    display: flex;
    flex-direction: column;
    /* gap: 5%; */
    width: 50px;
  }
  .absolute {
    top: 15px;
    display: flex;
    flex-direction: column;
    gap: 500px;
  }
}