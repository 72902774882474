.dash-title {
    font-size: 28px;
    text-align: left; /* Default LTR */
}

.ai-name, .ai-details {
    text-align: left; /* Default LTR */
}

/* RTL adjustments for Arabic */
.rtl .dash-title, 
.rtl .ai-name, 
.rtl .ai-details {
    text-align: right;
}

.d{
    display: flex;
    gap: 1  0px;
}
.d1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 3rem 1rem;
}
.d2{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    margin: 3rem 1rem;
    width: 40%;
}
.grad-cont{
        position: relative;
        padding: 2px;
        width: 100%;
        background: linear-gradient(60deg,rgba(99, 35, 98, 1), rgba(180, 163, 9, 1),rgba(0, 0, 128, 1));
        border-radius: 20px;
        transition: all 0.4s ease;
}
.ai{
    position: relative;
    padding: 2px;
    width: 100%;
    background: linear-gradient(60deg,rgba(99, 35, 98, 1), rgba(180, 163, 9, 1),rgba(0, 0, 128, 1));
    border-radius: 20px;
    transition: all 0.4s ease;
    cursor: pointer;
}
.ai-part{
    position: relative;
    padding: 2px;
    width: 100%;
    background: linear-gradient(60deg,rgba(99, 35, 98, 1), rgba(180, 163, 9, 1),rgba(0, 0, 128, 1));
    border-radius: 20px;
    transition: all 0.4s ease;
}
.grad-bg{
    background-color: #000;
    /* padding: 50px; */
    border-radius: 20px;
    padding: 10px;
}
.ai-models{
    background-color: #000;
    /* padding: 50px; */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
.ai-model{
    background-color: #000;
    /* padding: 50px; */
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}
.ai-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}
.ai-details{
    text-align: left;
    font-size: small;
    margin-top: 5%;
}
.ai-item {
    display: flex;
    flex-direction: row;

}

.ai-title {
    /* font-weight: bold; */
    margin-bottom: 5px;
}
.p2{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap:  20px; 
    grid-template-areas: 
      ". ."; 
}
@media screen and (max-width: 580px) {
    .d{
        display: flex;
        flex-direction: column;
        gap: 1  0px;
    }
  }