.right {
  display: flex;

  /* height: 50px; */
}

.buttt {
  position: relative;
  font-size: 1em;
  height: 100%;
  padding: 0.2em 0.8em;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  box-shadow: transparent;
}

.buttt:hover {
  text-shadow: 0 0 4px #ffe825;
  border-color: #ffe825 !important;
}

.butt:hover {
  /* background-color: #ffc75d !important; */
  box-shadow: 0 0 34px #ff0c0861 !important;
  text-shadow: 0 0 4px #ffe825;
  border-color: #ffe825 !important;
}

.contain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000000;
  /* padding: 20px; */
  height: 38px;
}

.mid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.obj1 {
  width: 10%;
}

.searchInput {
  margin-left: 45px;
}

.header {
  /* background-color: transparent; */
  margin: 40px 40px 0 40px;
  color: #fff;
}

.logo {
  cursor: pointer;
  float: left;
  margin-right: 10px;
}

.logo img {
  width: 100px;
  height: auto;
  margin-left: 0px;
  padding: 9px 0;
}

.logo a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.main-nav {
  display: flex;
  flex-direction: row;
}

.main-nav a {
  list-style: none;

}

.main-nav a {
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}

.main-nav a:hover {
  color: #3e92a3;
}

.main-nav a:focus:after,
.main-nav a:hover:after {
  width: 100%;
  left: 0%;
}

.main-nav a:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 20%;
  position: absolute;
  width: 0%;
  height: 2px;
}

.main-nav a {
  color: #fff;
  text-decoration: none;
  margin: auto 30px auto 0;
}

/* styles.css or any other global stylesheet */
.main-nav a .active-link {
  color: #3e92a3;
}

.menu-btn a .active-link {
  color: #3e92a3;
}

.main-nav a:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #3e92a3;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

.main-nav a.active-link:after {
  color: #3e92a3;
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 0;
  /* Adjusted left position to start from the beginning of the link */
  position: absolute;
  width: 100%;
  /* Adjusted width to cover the entire width of the link */
  height: 2px;
  background-color: #3e92a3;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1), left 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.menu-btn {
  display: none;
}

@media screen and (max-width: 580px) {
  .right {
    gap: 0;
  }

  .buttt {
    display: none;
  }

  .searchInput {
    margin-left: 0px;
    margin-right: 15px;
  }

  .header {
    /* background-color: transparent; */
    margin: 20px 20px;
  }

  .butt {
    font-size: 15px;
  }

  .signup-button::after {
    content: "Get Started";
  }

  .signup-button {
    visibility: hidden;
  }

  .signup-button::after {
    visibility: visible;
    font-size: 1px;
  }
}