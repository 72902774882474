.search-input  {
  /* font-size: 1em; */
  width: 100%;
  padding: 0.6em 2.5em 0.6em 0.8em; /* Add right padding to make space for the icon */
  border-radius: 30px;
  border: 1px solid transparent;
  font-size: 0.8em;
  color: rgb(255, 255, 255);
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  background-color: black;
  padding: 0.6em 0.8em;

}

.search-container {
  /* display: flex; */

  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%; /* Adjust to 100% to allow responsiveness */
  max-width: 200px; /* Set a max-width for larger screens */
  /* height: 37px; */
  padding: 3px 4px 3px 3px;
  background: linear-gradient(60deg, rgba(99, 35, 98, 1), rgba(180, 163, 9, 1), rgba(0, 0, 128, 1));
  border-radius: 30px;
  transition: all 0.4s ease;
}

.search-header__button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

}

.search-container:hover {
  background: linear-gradient(190deg, #03a9f4, #f441a5);
  filter: blur(0.0em);
  transition: filter 0.4s ease;
}
